import CandidatePageRoutes from "./CandidatePageRoutes";
import CandidateRecruiterPageRoutes from "./CandidateRecruiterPageRoutes";
import ClientPageRoutes from "./ClientPageRoutes";
import CreditsPageRoutes from "./CreditsPageRoutes";
import IndexPageRoutes from "./IndexPageRoutes";
import MessagesPageRoutes from "./MessagesPageRoutes";
import OpportunityPageRoutes from "./OpportunityPageRoutes";
import OrganizationPageRoutes from "./OrganizationPageRoutes";
import RecruiterChatbotPageRoutes from "./RecruiterChatbotPageRoutes";
import SalesPageRoutes from "./SalesPageRoutes";
import TalentSeekerPageRoutes from "./TalentSeekerPageRoutes";
import TorreGeniusesPageRoutes from "./TorreGeniusesPageRoutes";
import UtilityPageRoutes from "./UtilityPageRoutes";
import { PagePermissionManager } from "@/helpers/PermissionHelper";

const IndexRouteManager = new PagePermissionManager(IndexPageRoutes);
const CandidateRouteManager = new PagePermissionManager(CandidatePageRoutes);
const CandidateRecruiterRouteManager = new PagePermissionManager(CandidateRecruiterPageRoutes);
const ClientRouteManager = new PagePermissionManager(ClientPageRoutes);
const CreditsRouteManager = new PagePermissionManager(CreditsPageRoutes);
const MessagesRouteManager = new PagePermissionManager(MessagesPageRoutes);
const OpportunityRouteManager = new PagePermissionManager(OpportunityPageRoutes);
const OrganizationRouteManager = new PagePermissionManager(OrganizationPageRoutes);
const RecruiterChatbotRouteManager = new PagePermissionManager(RecruiterChatbotPageRoutes);
const SalesRouteManager = new PagePermissionManager(SalesPageRoutes);
const TalentSeekerRouteManager = new PagePermissionManager(TalentSeekerPageRoutes);
const TorreGeniusesRouteManager = new PagePermissionManager(TorreGeniusesPageRoutes);
const UtilityRouteManager = new PagePermissionManager(UtilityPageRoutes);

export {
  IndexRouteManager,
  CandidateRouteManager,
  CandidateRecruiterRouteManager,
  ClientRouteManager,
  CreditsRouteManager,
  MessagesRouteManager,
  OpportunityRouteManager,
  OrganizationRouteManager,
  RecruiterChatbotRouteManager,
  SalesRouteManager,
  TalentSeekerRouteManager,
  TorreGeniusesRouteManager,
  UtilityRouteManager
};

export default IndexRouteManager.concatAll([
  CandidateRouteManager,
  CandidateRecruiterRouteManager,
  ClientRouteManager,
  CreditsRouteManager,
  MessagesRouteManager,
  OpportunityRouteManager,
  OrganizationRouteManager,
  RecruiterChatbotRouteManager,
  SalesRouteManager,
  TalentSeekerRouteManager,
  TorreGeniusesRouteManager,
  UtilityRouteManager
]);
