import { ActionTree, GetterTree, MutationTree } from "vuex";

import { PermissionCategory, PermissionTinyDTO } from "@/services/DTOs/Permission";
import { DiscoveryHash } from "@/services/HashService";
import { PermissionService } from "@/services/PermissionService";
import { RootState } from "@/store/index";

export interface IOperatorPermissionStoreState {
  permissions: PermissionTinyDTO[];
}

interface IOperatorPermissionStore {
  state: IOperatorPermissionStoreState;
  mutations: MutationTree<IOperatorPermissionStoreState>;
  actions: ActionTree<IOperatorPermissionStoreState, RootState>;
  getters: GetterTree<IOperatorPermissionStoreState, RootState>;
}

export const state = (): IOperatorPermissionStore["state"] => ({
  permissions: [] as PermissionTinyDTO[]
});

export const mutations: IOperatorPermissionStore["mutations"] = {
  SET_PERMISSIONS(state, permissions: PermissionTinyDTO[]) {
    state.permissions = [...permissions];
  },
  CLEAR_STATE(state) {
    state.permissions = [];
  }
};

export const actions: IOperatorPermissionStore["actions"] = {
  setPermissions({ commit }, permissions: PermissionTinyDTO[]) {
    commit("SET_PERMISSIONS", permissions);
  },
  clearState({ commit }) {
    commit("CLEAR_STATE");
  },
  fetchOperatorPermissions({ dispatch, commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      const operatorGgId = rootGetters.bioPersonGgId as string | undefined;

      if (operatorGgId) {
        PermissionService.getOperatorPermissions(operatorGgId).subscribe({
          next: (permissions) => {
            commit("SET_PERMISSIONS", permissions);
            resolve();
          },
          error: (err) => {
            dispatch(
              "setNotification",
              { type: "error", message: err.message || err.data.message || "Error when fetching user permissions" },
              { root: true }
            );
            reject(err);
          }
        });
      } else {
        resolve();
      }
    });
  }
};

export const getters: IOperatorPermissionStore["getters"] = {
  permissions: (state: IOperatorPermissionStoreState): PermissionTinyDTO[] => {
    return state.permissions;
  },
  permissionsHash: (state: IOperatorPermissionStoreState): string => {
    return DiscoveryHash.numberToHash(state.permissions.reduce((acc, curr, idx) => acc + curr.id + idx, 0));
  },
  getByCategory: (state: IOperatorPermissionStoreState) => (category: PermissionCategory): PermissionTinyDTO[] => {
    return state.permissions.filter(permission => permission.category === category);
  },
  isPermittedToModifyPreFilledOperatorGgIdField: (state: IOperatorPermissionStoreState): boolean => {
    return state.permissions.some(permission =>
      permission.name === "modify.operator-prefilled-value.operator-gg-id" &&
      permission.category === PermissionCategory.ACTION
    );
  },
  isPermittedToDeleteTSOOperator: (state: IOperatorPermissionStoreState): boolean => {
    return state.permissions.some(permission =>
      permission.name === "delete.opportunity-tso-operator" &&
      permission.category === PermissionCategory.ACTION
    );
  },
  isPermittedToReviewRequest: (state: IOperatorPermissionStoreState): boolean => {
    return state.permissions.some(permission =>
      permission.name === "opportunity.review-request" &&
      permission.category === PermissionCategory.ACTION
    );
  },
  isPermittedToModifyOpportunityMemberVisibility: (state: IOperatorPermissionStoreState): boolean => {
    return state.permissions.some(permission =>
      permission.name === "modify.opportunity-member.visibility" &&
      permission.category === PermissionCategory.ACTION
    );
  },
  isPermittedToChangeMessageSender: (state: IOperatorPermissionStoreState): boolean => {
    return state.permissions.some(permission =>
      permission.name === "messages.send-message.variable-sender" &&
      permission.category === PermissionCategory.ACTION
    );
  }
};
