import { Person } from "@/services/DTOs/Person";

export const isTester = (person: Person): boolean => {
  const torrexSubjectIds = [
    "38278", // Abubakar Diallo
    "1262001", // Adrian Tomala
    "13276", // Agustín Rodríguez
    "555662", // Adriana Paternina
    "832725", // Alberto Montalti
    "655171", // Alessio Vargas
    "912091", // Alex Omar Fernández Lorenzo
    "13", // Alexander Torrenegra
    "832500", // Alexandra Rodriguez
    "607732", // Alexis Najarro
    "37291", // Alejandro Reyes Peña
    "16822", // Alfonso Rivera Gomez
    "144146", // Ana Maria Jaramillo Lopez
    "35", // Ana Maria Diaz
    "1227372", // Ana María Dueñas Manzano
    "607647", // Ana Velandia
    "255138", // Andrea Diaz Londaño
    "2654", // Andrés Cajiao
    "675815", // Andrés Francisco José Corso Pinzón
    "1242668", // Andres Felipe Fernandez
    "151289", // Andrés Felipe Rodríguez Rincón
    "1194284", // Andrés Felipe Valencia Barón
    "1162477", // Andres Garcia Lopez
    "9080", // Andrés González
    "903146", // Andres Camilo Meza Porto
    "142774", // Andrés Mauricio Jiménez García
    "12638", // Andro Lindsay
    "1201446", // Angelo Vásquez
    "1331216", // Anuar Shaidenov
    "1178459", // Augustin Ryckewart
    "477662", // Azalya Latorre Latorre
    "877660", // Benjamin Losada
    "1243550", // Brian Biderman
    "14778", // Camila M. García
    "1291279", // Camila Silva Erazo
    "36567", // Camilo Sanchez
    "860642", // Carlos Anriquez
    "1231779", // Carlos Carrasco
    "1179305", // Carlos Lopera
    "924114", // Carlos Sarmiento
    "738807", // Carolina Maria Gomez-Villafane
    "806175", // Carolina Montoya
    "64562", // Catalina González Salcedo
    "1070313", // Catalina Zarate
    "1035176", // Christian David Florez
    "554", // Daniela Avila Gomez
    "275895", // Daniela Bonilla Duran
    "1137766", // Daniela Cappa Garcia
    "1022877", // Daniela Gaviria
    "1052622", // Daniel Camilo Sandoval
    "865087", // Daniel Correa Laverde
    "1136848", // Daniel Guzmán
    "702673", // Dario Estupiñan
    "965994", // Darko Cebov
    "22290", // David Correa S.
    "22801", // David Elias Llinas Giraldo
    "1169806", // David Giraldo Valencia
    "1119041", // David Herrera
    "47", // David Montaño Ramírez
    "9237", // David Orban
    "52902", // David Velásquez Fariñas
    "22976", // David Velásquez Martínez
    "2102", // Daniel Cardona
    "1209081", // Daniel Gironza
    "1136848", // Daniel Guzman
    "2257", // Diana Aparicio
    "50406", // Diana Montoya Cujer
    "1072288", // Diego Fernando Franco Arcila
    "1057261", // Diego Revelli Lizarraga
    "1203875", // Diego Jesús Villamil Ruiz
    "197592", // Eddie Arrieta
    "607838", // Eduardo Uribe Mejia
    "229375", // Erick Rojas
    "1213479", // Esteban Gutiérrez Sarmiento
    "1323268", // Esther Humes
    "52662", // Emmah Kimari
    "1066618", // Emiliano Britez
    "51594", // Emiliano Chiappero
    "1188366", // Fabian Andres Barreto Rodríguez
    "14330", // Fabricio Orrala
    "1093440", // Farith Comas
    "1056711", // Federico Romero
    "1208992", // Fernanda Vargas Assis Fernandes
    "973141", // Francisco Javier Benavides
    "1134639", // Francisco Ruiz Flores
    "1197271", // Franco Mario Ciresa
    "1052535", // Gabriel Del Pino
    "879186", // Gabriela Enríquez Meléndez
    "1052651", // Gabriela Romero Trujillo
    "1100548", // Georgina Vedovelli Gurascier
    "155251", // German David Ruiz Figueroa
    "110820", // Gustavo García
    "1041860", // Gustavo Armando Ochoa
    "1292315", // Gustavo Adolfo Penaloza Cepeda
    "713130", // Hachimi Yallaoui
    "51623", // Hanniel Joseph
    "133430", // Héctor Adolfo Ituarte Pérez
    "163588", // Helene Goyat
    "17199", // Hermann Cardona
    "1101886", // Ignacio Martin Minenna
    "1210930", // Irving Michelle Rios Castellanos
    "40017", // Ivan Castañeda
    "981631", // Jaime Torres
    "1316859", // Jairo Andres Estupiñan Saavedra
    "755957", // Javier Castro
    "37177", // Javier Riveros
    "1342380", // Jawaria Tahir
    "43664", // Jessica Pinzon Frias
    "1122124", // Jesús Lucena
    "696701", // Jhalmar Antonio Batista Castro
    "1055210", // Jhon Fredy Marín Parra
    "1175344", // Jhon Martinez Castro
    "1259613", // John Santos Ndiwayesu
    "1055010", // Jonathan Hernandez Zapata
    "15418", // Jorge Andres Bocanegra
    "258534", // Jorge Angarita
    "1152798", // Jorge Lavalle de Zamacona
    "790095", // Jorge Jerez
    "155838", // Jorge Valdez
    "648067", // Jose Alberto Aldave
    "1219134", // José Carlos Vázquez Gutiérrez
    "534817", // José Francisco Berrío Garcia
    "39437", // José Manuel Puicón Rodas
    "28478", // Josephine Tse
    "1073336", // Josealfredo Peña Pita
    "28458", // Juan Camilo Castañeda
    "237032", // Juan Camilo Torres
    "1343301", // Juan Daniel Fernando Casallas Varela
    "1225916", // Juan David Betancourt
    "18692", // Juan David Granados
    "163315", // Juan David Pinilla
    "138920", // Juan Felipe Bonilla García
    "1042594", // Juan Francisco Laso Delgado
    "14348", // Juan Fernando Domínguez
    "13127", // Juan José Albán Ortiz
    "1196333", // Juan Manuel Salvadé
    "990485", // Juan Pablo Andrade
    "50985", // Juan Pablo Sánchez Zuluaga
    "1064888", // Juan Pablo Santa García
    "587131", // Juliana Prieto Arcila
    "1349671", // Julian Eduardo Acuña
    "515812", // Julian Granados S
    "155291", // Julio César Espinosa Herrera
    "68338", // Justin Petsche
    "48751", // Kalib Hackin
    "1155757", // Kaoli E. Jasso Oliveros
    "31176", // Kenneth Aladi
    "1182343", // Kenny Lajara
    "37609", // Kingsley Omotayo
    "816069", // Kubilay Caglayan
    "1239478", // Laura Alejandra Pedraza Uruena
    "1176103", // Laura Daniela Mojica Vásquez
    "70712", // Laura Hernandez
    "447165", // Laura María Reyes Tobar
    "1177505", // Laura Milena Sánchez Forero
    "218266", // Laura Natalia Motta Cadena
    "22514", // Laura Salazar
    "1340230", // Laura Sofia Romero Cortes
    "215877", // Laura Ximena Sierra González
    "1213049", // Lawrence Dow
    "1177490", // Leidy Tatiana Palomino Videz
    "14832", // Liliana Merchán
    "1160095", // Lissette Lorena Cárdenas González
    "1154855", // Lubianca Mayeli Vanegas Vega
    "5070", // Luis Andres Mesa Fajardo
    "1153144", // Luis Eduardo Brochet Fernandez
    "1061825", // Luis Felipe Pita Ahumada
    "54880", // Luis Fernando florian rosales
    "1259415", // Luis Gerardo Zaldivar Guereca
    "25946", // Luis Schmiel Paredes
    "1081239", // Luz Ángela González Ruiz
    "33347", // Mackley Carmona Álvarez
    "28814", // Manuel Fajardo
    "37", // Manuel Montes
    "1198501", // Manuela Zuluaga
    "13130", // Marco acosta
    "1338269", // Marco Antonio Cantero Rado
    "791203", // Marcos Alejandro Suarez
    "1148423", // Marco Manuel Ordoñez Rodas
    "22556", // María A. Moya
    "1126037", // Maria Alejandra Franco
    "1135562", // Maria Alejandra Gómez
    "1241508", // Maria Camila Diettes
    "1034624", // Maria Camila Nariño
    "1337306", // Maria Camila Rojas
    "1164202", // Maria Catalina Morales Navarrete
    "1061760", // Maria Fernanda Diaz Ramirez
    "16180", // Maria Fernanda Mosquera Noy
    "1135927", // María Gabriela Donoso Delgado
    "1164463", // Maria Gaviria
    "1187465", // Maria Isabel Silva Baquero
    "1041918", // Maria Juliana Pardo
    "1076710", // Maria Paula Simmonds Manrique
    "1062012", // Marian Orjuela
    "485335", // Mario Ramírez
    "961859", // Mario Villegas
    "1220417", // Martiniano Leguizamón
    "1038116", // Mateo Contreras Castellanos
    "1192176", // Mateo Eissner Romero
    "32183", // Mateo Upegui Borja
    "1243560", // Mauricio Giraldo Jiménez
    "22659", // Mauricio Zabala
    "22645", // Melissa Erasso Rodríguez
    "658821", // Melisa Medina Gomez
    "1340578", // Mehtap Gunay
    "1155310", // Michael Eisbrener
    "729573", // Miguel Parra
    "1190203", // Mikaela German
    "1163322", // Mugirase
    "1106562", // Mugirase Emmanuel
    "15635", // Muhammad Umar
    "1101961", // Natalia Arrieta Aycardi
    "1309860", // Natalia Galeano Riaño
    "1173916", // Natalia Sánchez Rodríguez
    "190237", // Natalia Torres Castro
    "1039347", // Natalia Rodríguez Pinzón
    "1047363", // Nayib Libos López
    "1225059", // Neider Fayad
    "14463", // Nelson Nelson-Atuonwu
    "1026819", // Nelly Cacho
    "1183954", // Nicolas Pardo Duran
    "1249502", // Nicolás Rey Noriega
    "7523", // Nicole LeDoux
    "257012", // Nicole Sánchez
    "1155852", // Okiemute Izobo
    "1205357", // Oscar Garcia
    "1152234", // Oscar Julián Castañeda Gutiérrez
    "168129", // Oscar Nava Trujillo
    "1195853", // Pablo Salamanca
    "1240511", // Paola Andrea Rodríguez Gómez
    "34346", // Paula Andrea Gutierrez Zapata
    "51031", // Paula Camila Corredor
    "1091693", // Paula Katherine Lozano
    "1100518", // Paula Marulanda
    "87200", // Pamela Sánchez
    "1075075", // Patrick Joel
    "1342500", // Rachel Osewe
    "889437", // Rafael Salvador Frieri Cabrera
    "1273222", // Rafael Velarde Lara
    "37830", // Raul Vasquez
    "1186503", // Ravena Candian Delgado
    "1061565", // Raynier Guio
    "14714", // Renan Peixoto da Silva
    "1120883", // Ricardo Adolfo Saavedra Lozano
    "1183460", // Ricardo Alzate Osorio
    "35472", // Ricardo Daza
    "1201242", // Rodolfo Dassaev Tagle Mesta
    "741", // Rodrigo Herrero
    "1128918", // Rolando Zamora Quintero
    "49626", // Roy ten Berge
    "35713", // Sadiq Yusuf Ilu
    "541288", // Samuel Gomez
    "1179245", // Sandro Andreo Moreno
    "129180", // Santiago González
    "1239625", // Santiago Higuera
    "271916", // Santiago Mendoza
    "1244918", // Santiago Linares
    "15778", // Santiago Liñán Romero
    "431716", // Santiago Noya Parera
    "37480", // Santiago Reyes Londoño
    "1095491", // Santiago Saavedra Arévalo
    "817060", // Sebastián Almeida
    "22791", // Sebastián Cuervo
    "11836", // Sebastian Gallo
    "15852", // Sebastián Martínez
    "1134335", // Sebastián Morón Oliva
    "1277654", // Sebastián Osorno Tobón
    "49062", // Sebastian Ruiz
    "1155383", // Sebastian Siachoque
    "1164144", // Sebastian Tuyu
    "1247872", // Sergio Andres Iriarte Amador
    "26254", // Seun Kujebe
    "1252635", // Silvana Camila Paez Tamayo
    "1258890", // Simran Duseja
    "22571", // Shankar Shethe
    "1001455", // Solange Salazar Yagual
    "1151139", // Sofia Palazzo
    "1234199", // Stephen Echeruo
    "595450", // Susana Ayala
    "364261", // Susana Leticia de la Garza Escamilla
    "1051308", // Tatiana Vélez Patiño
    "1069044", // Trung Dung Truong
    "1238062", // Tomas Lawson
    "645162", // Valeria Beltrán
    "103200", // Valentina Gonzalez,
    "203160", // Valentina Parra
    "1125200", // Vanessa Castro
    "1323194", // Vanessa Hincapié
    "632788", // Vanessa Melina Cuentas Cordova
    "727977", // Veronica Ortiz
    "1168956", // Victoria Carrera
    "516519", // Viviana Trujillo
    "1328669", // William Armando Izquierdo Perez
    "34175", // Wilson Contreras
    "527809", // Wilson Peña
    "1238366", // Yann Bula
    "50328", // Zacarias Jorge
    "1181148", // Zandokan Villarreal
    "31254" // Zihao Henry Huang
  ];
  const { subjectId, email } = person;

  return !!subjectId && !!email && (torrexSubjectIds.includes(String(subjectId)) || email.includes("@torre.co"));
};
