import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Opportunities",
    to: "/candidate-recruiter/opportunities",
    icon: "mdi-briefcase-search",
    permissions: [
      {
        name: "candidate-recruiter.opportunities",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Assign candidate recruiter",
    to: "/candidate-recruiter/assign",
    icon: "mdi-account-arrow-right",
    permissions: [
      {
        name: "candidate-recruiter.assign",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Decode credentials",
    to: "/candidate-recruiter/decode-credentials",
    icon: "mdi-account-key",
    permissions: [
      {
        name: "candidate-recruiter.decode-credentials",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Import TS candidates",
    to: "/candidate-recruiter/import-candidates",
    icon: "mdi-database-arrow-up-outline",
    permissions: [
      {
        name: "candidate-recruiter.import-candidates",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
