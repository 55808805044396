import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Manage an organization",
    to: "/organization/manage",
    icon: "mdi-domain",
    permissions: [
      {
        name: "organization.manage",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
