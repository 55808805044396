import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Candidate Information",
    to: "/candidate/candidate-information",
    icon: "mdi-account",
    permissions: [
      {
        name: "candidate.candidate-information",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Mark as Access Cohort",
    to: "/candidate/update-flags-access-cohort",
    icon: "mdi-account-check",
    permissions: [
      {
        name: "candidate.update-flags-access-cohort",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Send urgent message",
    to: "/candidate/send-urgent-message",
    icon: "mdi-message-alert",
    permissions: [
      {
        name: "candidate.send-urgent-message",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Send regular message",
    to: "/candidate/send-regular-message",
    icon: "mdi-message",
    permissions: [
      {
        name: "candidate.send-regular-message",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Upload test result",
    to: "/candidate/upload-test-result",
    icon: "mdi-file-upload",
    permissions: [
      {
        name: "candidate.upload-test-result",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Mark Person Flags",
    to: "/candidate/candidate-update-external-person-flags",
    icon: "mdi-account-alert",
    permissions: [
      {
        name: "candidate.candidate-update-external-person-flags",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Undo imported resume",
    to: "/candidate/candidate-external-undo-imported-resume",
    icon: "mdi-undo-variant",
    permissions: [
      {
        name: "candidate.candidate-external-undo-imported-resume",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
