import { ActionTree } from "vuex";

import { ISubscriptionDTO } from "@/services/DTOs/SubscriptionsDTO";
import { SubscriptionService } from "@/services/SubscriptionService";

export const state = () => ({
  subscription: undefined as ISubscriptionDTO | undefined
});

export type SubscriptionState = ReturnType<typeof state>;

export const mutations = {
  SET_SUBSCRIPTION(state: SubscriptionState, subscription: ISubscriptionDTO) {
    state.subscription = subscription;
  },
  UNSET_SUBSCRIPTION(state: SubscriptionState) {
    if (state.subscription) {
      state.subscription.active = false;
    } else {
      state.subscription = undefined;
    }
  }
};

export const actions: ActionTree<SubscriptionState, SubscriptionState> = {
  getSubscription({ commit, dispatch }, ggId: string) {
    SubscriptionService.getSubscription(ggId)
      .subscribe({
        next: (data: ISubscriptionDTO) => {
          commit("SET_SUBSCRIPTION", data);
        },
        error: (error: any) => {
          dispatch("setNotification", {
            type: "error",
            message: error.data.message || "Error getting subscription"
          }, { root: true });
        }
      });
  },
  updateSubscription({ commit }, data: ISubscriptionDTO[]) {
    commit("SET_SUBSCRIPTION", data);
  },
  clearSubscription({ commit }) {
    commit("UNSET_SUBSCRIPTION");
  },
  unsubscribe({ commit, dispatch }, ggId: string) {
    SubscriptionService.unsubscribe({
      subscriberGgId: ggId
    }).subscribe({
      next: () => {
        commit("UNSET_SUBSCRIPTION");
        dispatch("setNotification", {
          type: "success",
          message: "Unsubscribed from the automated ticket assignement system"
        }, { root: true });
      },
      error: (error) => {
        dispatch("setNotification", {
          type: "error",
          message: error.data.message || "Error unsubscribing"
        }, { root: true });
      }
    });
  }
};

export const getters = {
  isSubscribed: (state: SubscriptionState) => state.subscription ? state.subscription.active : false,
  subscription: (state: SubscriptionState) => state.subscription
};
