import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "New agile sale",
    to: "/sales/agile",
    icon: "mdi-cash-plus",
    permissions: [
      {
        name: "sales.agile",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
