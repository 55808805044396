import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    icon: "mdi-briefcase",
    text: "Job opening",
    desc: "Perform some functions on opportunities",
    to: "/opportunity",
    permissions: [
      {
        name: "opportunity",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-briefcase-plus",
    text: "Talent Seeker",
    desc: "Perform some functions on talent seekers",
    to: "/talent-seeker",
    permissions: [
      {
        name: "talent-seeker",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-briefcase-search",
    text: "Candidates",
    desc: "Perform some functions on job seekers",
    to: "/candidate",
    permissions: [
      {
        name: "candidate",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-domain",
    text: "Organization",
    desc: "Perform some functions on organizations",
    to: "/organization",
    permissions: [
      {
        name: "organization",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-briefcase-account",
    text: "Client",
    desc: "Perform some functions on clients",
    to: "/client",
    permissions: [
      {
        name: "client",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-tools",
    text: "Utilities",
    desc: "Perform some miscellaneous functions",
    to: "/utility",
    permissions: [
      {
        name: "utility",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-account-cog-outline",
    text: "Candidate recruiter",
    desc: "Perform some functions on candidate recruiter",
    to: "/candidate-recruiter",
    permissions: [
      {
        name: "candidate-recruiter",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-robot",
    text: "Recruiter chatbot",
    desc: "Perform functions related with the recruiter chatbot and his flows",
    to: "/recruiter-chatbot",
    permissions: [
      {
        name: "recruiter-chatbot",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-briefcase-search",
    text: "Torre Geniuses",
    desc: "Actions for Torre Geniuses",
    to: "/torre-geniuses",
    permissions: [
      {
        name: "torre-geniuses",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-format-list-bulleted",
    text: "Task properties",
    desc: "View task properties",
    to: "/view-task-properties",
    permissions: [
      {
        name: "utility.task-properties",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-checkbox-marked-circle-auto-outline",
    text: "Tasks",
    desc: "View tasks",
    to: "/opportunity/tasks",
    permissions: [
      {
        name: "opportunity.tasks",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-message-outline",
    text: "Messages",
    desc: "Perform messages related functions",
    to: "/messages",
    permissions: [
      {
        name: "messages",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-sack",
    text: "Sales",
    desc: "Manage sales",
    to: "/sales",
    permissions: [
      {
        name: "sales",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    icon: "mdi-bank",
    text: "Credits",
    desc: "Manage credits",
    to: "/credits",
    permissions: [
      {
        name: "credits",
        category: PermissionCategory.PAGE
      }
    ]
  }
];
