import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Assign Account Manager To Talent Seeker",
    to: "/talent-seeker/assign-account-manager",
    icon: "mdi-account-supervisor",
    permissions: [
      {
        name: "talent-seeker.assign-account-manager",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Talent Seeker Information",
    to: "/talent-seeker/talent-seeker-information",
    icon: "mdi-account-details",
    permissions: [
      {
        name: "talent-seeker.talent-seeker-information",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Create Public CCG",
    to: "/talent-seeker/create-public-ccg",
    icon: "mdi-account-plus",
    permissions: [
      {
        name: "talent-seeker.create-public-ccg",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Onboard Talent Seeker",
    to: "/talent-seeker/update-onboarded-status",
    icon: "mdi-account-supervisor",
    permissions: [
      {
        name: "talent-seeker.update-onboarded-status",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Genome Token",
    to: "/talent-seeker/genome-token",
    icon: "mdi-account-key",
    permissions: [
      {
        name: "talent-seeker.genome-token",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Early Access Flag",
    to: "/talent-seeker/update-early-access-flag",
    icon: "mdi-account-key",
    permissions: [
      {
        name: "talent-seeker.update-early-access-flag",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
