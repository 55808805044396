import { isUndefined, partition } from "lodash-es";
import { ActionTree } from "vuex";

import { sortStringOptionsAlphabetically } from "@/helpers/ArrayHelpers";
import { CortanaService } from "@/services/CortanaService";
import { ISelectOption, IStringSelectOption } from "@/services/DTOs/Misc";
import { ITaskPropertiesDTO } from "@/services/DTOs/TaskDTO";

export const state = () => ({
  taskProperty: undefined as ITaskPropertiesDTO | undefined,
  taskProperties: [] as ITaskPropertiesDTO[]
});

export type TaskPropertiesState = ReturnType<typeof state>;

export const mutations = {
  SET_TASK_PROPERTY: (storeState: TaskPropertiesState, taskProperty?: ITaskPropertiesDTO) => {
    storeState.taskProperty = taskProperty;
  },
  SET_TASK_PROPERTIES: (storeState: TaskPropertiesState, taskProperties: ITaskPropertiesDTO[]) => {
    storeState.taskProperties = taskProperties;
  }
};

export const actions: ActionTree<TaskPropertiesState, TaskPropertiesState> = {
  getAllTaskProperties({ commit, dispatch }) {
    return CortanaService.getTaskProperties().subscribe({
      next: (taskProperties: ITaskPropertiesDTO[]) => commit("SET_TASK_PROPERTIES", [...taskProperties]),
      error: () => {
        dispatch("setNotification",
          {
            type: "error",
            message: "Error fetching task properties"
          },
          { root: true }
        );
        commit("SET_TASK_PROPERTIES", []);
      }
    });
  }
};

export const getters = {
  taskProperties: (currentState: TaskPropertiesState): ITaskPropertiesDTO[] => {
    return currentState.taskProperties;
  },
  taskPropertiesAsSelectOptions: (currentState: TaskPropertiesState): IStringSelectOption[] => {
    return getTaskPropertySelectOptions<IStringSelectOption>(currentState.taskProperties)(
      tp => ({ value: tp.taskTrigger, text: tp.name ?? tp.taskTrigger }),
      tp => ({ value: tp.taskTrigger, text: prefixUnshipped(tp) }),
      sortStringOptionsAlphabetically
    );
  }
};

const prefixUnshipped = (tp: ITaskPropertiesDTO) => `[Unshipped] ${tp.name ?? tp.taskTrigger}`;

const getTaskPropertySelectOptions = <T = ISelectOption>(taskProperties: ITaskPropertiesDTO[]) =>
  (
    buildActiveSelectOptionFn: (tp: ITaskPropertiesDTO) => T,
    buildUnshippedSelectOptionFn: (tp: ITaskPropertiesDTO) => T,
    sortOptionsAlphabeticallyFn: (a: T, b: T) => number
  ) => {
    const [activeTasks, unshippedTasks] = partition(taskProperties, tp => isUndefined(tp.unshipped));
    const [activeHeader, unshippedHeader] = [
      { header: "Active tasks", divider: true, value: "" },
      { header: "Unshipped tasks", divider: true, value: "" }
    ] as IStringSelectOption[];

    return [
      activeHeader,
      ...activeTasks
        .map(buildActiveSelectOptionFn)
        .sort(sortOptionsAlphabeticallyFn),
      unshippedHeader,
      ...unshippedTasks
        .map(buildUnshippedSelectOptionFn)
        .sort(sortOptionsAlphabeticallyFn)
    ];
  };
