import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "View job opening approval requests",
    to: "/utility/opp-approval-request-logs",
    icon: "mdi-check-circle-outline",
    permissions: [
      {
        name: "utility.opp-approval-request-logs",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Set god mode (discovery.person_flags.opportunity_operator)",
    to: "/utility/update-person-operator-flag",
    icon: "mdi-account-check",
    permissions: [
      {
        name: "utility.update-person-operator-flag",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "View Torre messenger logs",
    to: "/utility/torre-messenger-logs",
    icon: "mdi-message-text",
    permissions: [
      {
        name: "utility.torre-messenger-logs",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "View/Edit task properties",
    to: "/utility/task-properties",
    icon: "mdi-card-bulleted",
    permissions: [
      {
        name: "utility.task-properties",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "View active subscriptions",
    to: "/utility/active-subscriptions",
    icon: "mdi-account-group",
    permissions: [
      {
        name: "utility.active-subscriptions",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "View Operators",
    to: "/utility/operators",
    icon: "mdi-account-multiple",
    permissions: [
      {
        name: "utility.operators",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Manage permissions",
    to: "/utility/permissions",
    icon: "mdi-shield",
    permissions: [
      {
        name: "utility.permissions",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Manage permissions groups",
    to: "/utility/permission-groups",
    icon: "mdi-shield-account",
    permissions: [
      {
        name: "utility.permission-groups",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "View all messages exchanged",
    to: "/utility/all-messages",
    icon: "mdi-message-text",
    permissions: [
      {
        name: "utility.all-messages",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
