import { ActionTree } from "vuex";

export const state = () => ({
  initial: {},
  current: {},
  formKeys: [] as string[]
});

export type FormHelperState = ReturnType<typeof state>;

export const mutations = {
  SET_INITIAL_DATA: (storeState: FormHelperState, initial?: Object) => {
    storeState.initial = initial ?? {};
  },
  SET_CURRENT_DATA: (storeState: FormHelperState, current?: Object) => {
    storeState.current = current ?? {};
  },
  SET_FORM_KEYS: (storeState: FormHelperState, formKeys?: string[]) => {
    storeState.formKeys = formKeys ?? [];
  },
  CLEAN_FORM_DATA: (storeState: FormHelperState) => {
    storeState.initial = {};
    storeState.current = {};
    storeState.formKeys = [];
  }
};

export const actions: ActionTree<FormHelperState, FormHelperState> = {
  confirmLeave() {
    return window.confirm("Do you really want to leave? you have unsaved changes!");
  },
  async confirmStayInChangedForm({ commit, dispatch, getters }, externalFunction?: Function) {
    const isFormUnchanged = getters.isFormUnchanged;

    if (isFormUnchanged) {
      commit("CLEAN_FORM_DATA");
    } else {
      const leaveForm = await dispatch("confirmLeave") as boolean;

      if (!leaveForm) {
        return;
      }
      commit("CLEAN_FORM_DATA");
    }

    if (externalFunction) {
      externalFunction();
    }
  },
  setInitialData({ commit }, initial?: Object) {
    commit("SET_INITIAL_DATA", initial);
    commit("SET_CURRENT_DATA", initial);
    commit("SET_FORM_KEYS", initial ? Object.keys(initial) : []);
  },
  setCurrentData({ commit }, current?: Object) {
    commit("SET_CURRENT_DATA", current);
  },
  setFormKeys({ commit }, formKeys?: string[]) {
    commit("SET_FORM_KEYS", formKeys);
  },
  cleanFormData({ commit }) {
    commit("CLEAN_FORM_DATA");
  }
};

export const getters = {
  isFormUnchanged: (state: FormHelperState): boolean => {
    const { current, initial, formKeys } = state;

    return formKeys.every((key) => {
      const typedKeys = key as never;

      return JSON.stringify(initial[typedKeys]) === JSON.stringify(current[typedKeys]);
    });
  },
  currentData: (state: FormHelperState): Object => {
    return state.current;
  }
};
