import { ActionTree, GetterTree, MutationTree } from "vuex";

import { INumberSelectOption } from "@/services/DTOs/Misc";
import { IService } from "@/services/DTOs/TorreServices";
import { ServicesDefinitionService } from "@/services/ServicesDefinitionService";
import { RootState } from "@/store/index";

export interface ITorreServiceStoreState {
  services: IService[];
}

export interface ITorreServiceStore {
  state: ITorreServiceStoreState;
  mutations: MutationTree<ITorreServiceStoreState>;
  actions: ActionTree<ITorreServiceStoreState, RootState>;
  getters: GetterTree<ITorreServiceStoreState, RootState>;
}

export const state = (): ITorreServiceStore["state"] => ({
  services: [] as IService[]
});

export const mutations: ITorreServiceStore["mutations"] = {
  SET_SERVICES(state, services: IService[]) {
    state.services = [...services];
  }
};

export const actions: ITorreServiceStore["actions"] = {
  fetchAndUpdateServices({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      ServicesDefinitionService.get().subscribe({
        next: (services) => {
          commit("SET_SERVICES", services);
          resolve();
        },
        error: err => reject(err)
      });
    });
  }
};

export const getters: ITorreServiceStore["getters"] = {
  servicesDefinition: state => state.services,
  serviceNameByCode: state => (code: number) => state.services.find(service => service.code === code)?.name,
  serviceCodesAsSelectOptions: state => state.services.map(service => ({ value: service.code, text: service.name } as INumberSelectOption))
};
