import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Credits balance",
    to: "/credits/balance",
    icon: "mdi-bank",
    permissions: [
      {
        name: "credits.balance",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Add Credits",
    to: "/credits/add",
    icon: "mdi-cash-plus",
    permissions: [
      {
        name: "credits.add",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Spend Credits",
    to: "/credits/spend",
    icon: "mdi-cash-minus",
    permissions: [
      {
        name: "credits.spend",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
