import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Client detail",
    to: "/client/detail",
    icon: "mdi-account-details",
    permissions: [
      {
        name: "client.detail",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Clients",
    to: "/client/clients",
    icon: "mdi-format-list-bulleted",
    permissions: [
      {
        name: "client.clients",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
