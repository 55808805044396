import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Add email inbox",
    to: "/messages/email-inbox",
    icon: "mdi-message-text-outline",
    permissions: [
      {
        name: "messages.email-inbox",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Add/Update email signature",
    to: "/messages/email-signature",
    icon: "mdi-email-outline",
    permissions: [
      {
        name: "messages.email-signature",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "GPT Prompt",
    to: "/messages/gpt-prompt/prompt-session",
    icon: "mdi-message-flash-outline",
    permissions: [
      {
        name: "messages.gpt-prompt",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Message Tickets",
    to: "/messages/message-tickets",
    icon: "mdi-message-flash-outline",
    permissions: [
      {
        name: "messages.message-tickets",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Send Message",
    to: "/messages/send-message",
    icon: "mdi-message-text-outline",
    permissions: [
      {
        name: "messages.send-message",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
