import { ActionTree } from "vuex";

import { sortOperatorsAlphabetically } from "@/helpers/ArrayHelpers";
import { IOperator, OperatorRoles } from "@/services/DTOs/Person";
import { PersonService } from "@/services/PersonService";

export const state = () => ({
  operator: undefined as IOperator | undefined,
  operators: [] as IOperator[]
});

export type OperatorState = ReturnType<typeof state>;

export const mutations = {
  SET_OPERATOR: (storeState: OperatorState, operator?: IOperator) => {
    storeState.operator = operator;
  },
  SET_ACTIVE_OPERATORS: (storeState: OperatorState, operators: IOperator[]) => {
    storeState.operators = operators;
  }
};

export const actions: ActionTree<OperatorState, OperatorState> = {
  getAllActiveOperators({ commit, dispatch }) {
    return PersonService.getOperators().subscribe({
      next: (operators: IOperator[]) => commit("SET_ACTIVE_OPERATORS", [...operators].sort(sortOperatorsAlphabetically)),
      error: () => {
        dispatch("setNotification",
          {
            type: "error",
            message: "Error fetching active operators"
          },
          { root: true }
        );
        commit("SET_ACTIVE_OPERATORS", []);
      }
    });
  }
};

export const getters = {
  role: (currentState: OperatorState): string => {
    return currentState.operator
      ? currentState.operator.role
      : OperatorRoles.UNKNOWN;
  },
  activeOperatorIsAdmin: (currentState: OperatorState): boolean => {
    return currentState.operator?.isAdmin === true;
  },
  activeOperators: (currentState: OperatorState): IOperator[] => {
    return currentState.operators;
  },
  activeOperatorsAsSelectOptions: (currentState: OperatorState): Array<{ text: string, value: string }> => {
    return currentState.operators.map((operator) => {
      return { text: operator.bio.name, value: operator.operatorGgId };
    });
  },
  activeOperatorsWithIdsAsSelectOptions: (currentState: OperatorState): Array<{ text: string, value: number }> => {
    return currentState.operators.map((operator) => {
      return { text: operator.bio.name, value: operator.id };
    });
  },
  activeOperatorsAsMentions: (currentState: OperatorState): Array<{ text: string, value: string }> => {
    return currentState.operators.map((operator) => {
      return { text: operator.bio.name, value: operator.bio.publicId };
    });
  }
};
