import { ConfigCatService } from "@torre-labs/feature-flag";
import { ActionTree, Dispatch, GetterTree, MutationTree } from "vuex";

import nuxtConfig from "@/nuxt.config";
import { AuthService } from "@/services/AuthService";
import { EventNames, IEventDTO, IEventLoggerFunctionArguments } from "@/services/DTOs/EventDTO";
import { INotification, Notification } from "@/services/DTOs/Notification";
import { AuthUser, IAuthUserDTO, Person } from "@/services/DTOs/Person";
import { EventService } from "@/services/EventService";

const configCatSdkKey = process.env.configcatSDKKey as string;
const environmentMode = process.env.NODE_ENV !== "production" ? "development" : "production";

const fetchAppAndUserInitializationData = async(dispatch: Dispatch) => {
  await dispatch("getPersonFeatureFlags");
  await dispatch("OperatorPermission/fetchOperatorPermissions", {}, { root: true });
  await dispatch("TaskProperties/getAllTaskProperties", {}, { root: true });
  await dispatch("Operator/getAllActiveOperators", {}, { root: true });
  await dispatch("TorreService/fetchAndUpdateServices", {}, { root: true });
};

export const state = () => ({
  authUser: undefined as AuthUser | undefined,
  bioPerson: undefined as Person | undefined,
  notifications: [] as Notification[],
  jobPostBaseUrl: nuxtConfig.env.jobPostBaseUrl,
  isLoggingIn: false
});

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  isAuth: state => state.authUser !== undefined && state.bioPerson !== undefined,
  notifications: state => state.notifications,
  jobPostBaseUrl: state => state.jobPostBaseUrl,
  bioPersonGgId: state => state.bioPerson?.ggId,
  isLoggingIn: state => state.isLoggingIn
};

export const mutations: MutationTree<RootState> = {
  ON_AUTH_STATE_CHANGED_MUTATION(state, { authUser }) {
    state.authUser = authUser;
  },
  SET_BIO_PERSON(state, person: Person) {
    state.bioPerson = person;
  },
  SET_AUTH_USER(state, authUser: AuthUser) {
    state.authUser = authUser;
  },
  SET_NOTIFICATION(state, notification: Notification) {
    state.notifications = [...state.notifications, notification];
  },
  UNSET_NOTIFICATION(state, notificationId: number) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationId
    );
  },
  CLEAR_STATE(state) {
    state.authUser = undefined;
    state.bioPerson = undefined;
    state.notifications = [];
    state.isLoggingIn = false;
  },
  SET_IS_LOGGING_IN(state, isLoggingIn: boolean) {
    state.isLoggingIn = isLoggingIn;
  }
};

export const actions: ActionTree<RootState, RootState> = {
  async updateAuthUser({ commit, dispatch }, data: IAuthUserDTO) {
    const { authUser, operator } = data;

    commit("SET_AUTH_USER", new AuthUser({
      uid: authUser.uid,
      email: authUser.email,
      displayName: operator.bio.name,
      photoURL: operator.bio.pictureThumbnail,
      role: authUser.role,
      ggId: authUser.ggId,
      operatorId: authUser.operatorId
    }));
    commit("SET_BIO_PERSON", { ...operator.bio });
    commit("Operator/SET_OPERATOR", operator, { root: true });
    await fetchAppAndUserInitializationData(dispatch);

    const redirect = this.$router.currentRoute.query.redirectTo as
      | string
      | null;

    if (redirect) {
      const [path, query] = redirect.split("?");
      const queries = query ? Object.fromEntries(new URLSearchParams(query).entries()) : undefined;

      this.$router.replace({
        path,
        query: queries
      }).catch((_e) => {});
    } else {
      this.$router.push("/");
    }

    dispatch("getPersonFeatureFlags");
    dispatch("logEvent", { eventName: EventNames.USER_LOGGED_IN }, { root: true });
    commit("SET_IS_LOGGING_IN", false);
  },
  async onAuthStateChangedAction({ commit, dispatch }, { authUser }) {
    if (!authUser) {
      await dispatch("logoutAction", "undefined-authUser");
      return;
    }
    commit("SET_IS_LOGGING_IN", true);
    AuthService.fetchOperatorDetail(authUser).subscribe({
      next: (data) => {
        dispatch("updateAuthUser", data);
        dispatch("setNotification", { type: "success", message: "Login successful" });
      },
      error: (error: any) => {
        dispatch("setNotification", {
          type: "error",
          message: error.message || error.data.message || "Error getting google user"
        });
        dispatch("logoutAction", "error-getting-operator-detail");
      }
    });
  },
  setNotification({ commit }, notification: INotification) {
    commit("SET_NOTIFICATION", new Notification({ ...notification }));
  },
  unsetNotification({ commit }, notificationId: number) {
    commit("UNSET_NOTIFICATION", notificationId);
  },
  logoutAction({ dispatch, commit }, reason?: string) {
    console.warn("logoutReason", reason);
    AuthService.logoutWithFirebaseAuth(this.$fire.auth).subscribe({
      next: () => commit("CLEAR_STATE"),
      error: async(error: any) => await dispatch("setNotification", { type: "error", message: error }),
      complete: async() => await this.$router.push("/landing")
    });
  },
  loginAction({ dispatch }) {
    AuthService.loginWithFirebaseAuth(this.$fire.auth).subscribe({
      next: (data: IAuthUserDTO) => dispatch("updateAuthUser", data),
      error: (error: any) => {
        const message = error.message || error.data.message || "Error getting google user";

        dispatch("setNotification", {
          type: "error",
          message
        });
        dispatch("logoutAction", message);
      }
    });
  },
  getPersonFeatureFlags({ dispatch, state }) {
    const { authUser } = state;
    const configcatService = new ConfigCatService(configCatSdkKey, environmentMode);

    if (authUser) {
      const data = {
        user: {
          identifier: authUser.ggId,
          email: authUser.email
        },
        configcatService
      };

      dispatch("FeatureFlag/setFlags", data);
    }
  },
  logEvent(
    { state },
    { eventName, status, targetUserGgId, context }: IEventLoggerFunctionArguments
  ) {
    const payload = {
      event: `${eventName}${status ? "_" + status : ""}`,
      userGgId: state.bioPerson?.ggId,
      targetUserGgId,
      created: new Date().toISOString(),
      context
    } as IEventDTO;

    EventService.log(payload).subscribe({
      error(err) {
        console.error(err);
      }
    });
  }
};
