import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Essentials Onboarding",
    to: "/torre-geniuses/onboarding-flow/essentials",
    icon: "mdi-account-school",
    permissions: [
      {
        name: "torre-geniuses.onboarding-flow.essentials",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Agile Onboarding",
    to: "/torre-geniuses/onboarding-flow/agile",
    icon: "mdi-account-switch",
    permissions: [
      {
        name: "torre-geniuses.onboarding-flow.agile",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
